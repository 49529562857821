// main imports
import * as React from "react";

// plugin imports
import { Trans, useTranslation } from "react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

// style imports
import "./franquiciasConocerte.scss";

// svg imports
import Subrayado4 from "../../svg/subrayados/subrayado04.svg";

// components imports

// page
export default function FranquiciasConocerte({ cardStackImages }) {
	const { t } = useTranslation();
	const [animationCount, setAnimationCount] = React.useState(0);

	const cardStackImage = {
		hide: {
			opacity: 0,
			transition: { duration: 0 },
		},
		visible: {
			opacity: 1,
			transition: { duration: 0 },
		},
	};

	return (
		<div className="conocerteContainer">
			<div className="cardStack">
				<GatsbyImage
					image={getImage(cardStackImages[cardStackImages.length - 1])}
					alt=""
					className="cardStackImage"
				/>
				<motion.div
					className="cardStackAnimation"
					variants={{
						visible: {
							opacity: 0,
							transition: {
								delayChildren: 1,
								staggerChildren: 1,
								staggerDirection: -1,
								duration: 0,
								delay: 1,
								when: "afterChildren",
							},
						},
					}}
					initial="hide"
					animate="visible"
					// When we finish all the animations, we fade out (no elements are visible anyway) and then restart the component
					key={animationCount}
					onAnimationComplete={() => setAnimationCount(animationCount + 1)}
				>
					{cardStackImages.map((image, i) => {
						if (i !== cardStackImages.length - 1)
							return (
								<motion.div
									key={"cardStackImage" + i}
									className="cardStackImage"
									variants={cardStackImage}
									style={{ zIndex: 20 - i }}
								>
									<GatsbyImage image={getImage(image)} alt="" />
								</motion.div>
							);
						return null;
					})}
				</motion.div>
			</div>
			<div className="textContent">
				<p className="h2 lowercase">
					<Trans>
						Begin nace tras más de 15 años de experiencia de nuestros Socios en
						el mundo de la restauración organizada. Individualmente han
						construido conceptos tan sólidos como Saona, Voltereta, One Burger o
						Lassal con un total de más de 80 locales.
					</Trans>
				</p>

				<p className="h2 lowercase">
					<Trans>
						Ahora buscamos a nuestros partners estratégicos con espíritu
						emprendedor y pasión por los nuevos retos para formar parte de una
						marca disruptiva como Begin y asegurar su desarrollo en todo el
						ámbito nacional.
					</Trans>
				</p>

				<a
					className="franquiciaContactoCTA"
					href="#franquiciasContacto"
					aria-label={t("Contacta con nosotros")}
				>
					<span className="h2 lowercase">
						<Trans>Contacta con nosotros</Trans>
					</span>
					<Subrayado4 className="subrayado" />
				</a>
			</div>
		</div>
	);
}
