import "./ContactForm.scss";

import React from "react";
import { withPrefix } from "gatsby";
import axios from "axios";
import { Link } from "gatsby-plugin-react-i18next";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Subrayado1 from "../../svg/subrayados/subrayado01.svg";
import Separador from "../../svg/lineaSeparador.svg";

const FORM_ENDPOINT = "";

export default function FranquiciasForm() {
	const { t } = useTranslation();
	const [loading, setLoading] = React.useState(false);
	const [toSend, setToSend] = React.useState({
		from_email: "",
		name: "",
		phone: "",
		city: "",
		comment: "",
		experience: "",
		investment: "",
		section: "Franquicia",
		data_protection: "No",
		com_comercial: "No",
	});
	// console.log(toSend);

	const [formSent, setFormSent] = React.useState(false);
	const [formError, setFormError] = React.useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(false);
		setLoading(true);

		axios({
			method: "post",
			// url: "https://peim.es/php_test/begin/envioFranquicias.php", // for testing purposes
			url: withPrefix("/php/envioFranquicias.php"),

			data: {
				...toSend,
			},
		})
			.then((res) => {
				setLoading(false);

				setFormSent(true);
				// console.log(res);
			})
			.catch((err) => {
				setLoading(false);
				setFormError(true);
				console.log(err);
			});
	};

	const handleChange = (e) => {
		if (e.target.type === "checkbox") {
			return setToSend((prevState) => {
				return {
					...prevState,
					[e.target.name]: e.target.checked
						? "Si. Fecha: " +
						  new Date() +
						  ". Texto: " +
						  e.target?.labels[0]?.innerText
						: "No",
				};
			});
		}
		return setToSend((prevState) => {
			return { ...prevState, [e.target.name]: e.target.value };
		});
	};

	return (
		<div>
			<SwitchTransition mode="out-in">
				<CSSTransition key={formSent} timeout={400} classNames="fade">
					<>
						{!formSent ? (
							<div>
								<form
									action={FORM_ENDPOINT}
									onSubmit={handleSubmit}
									onChange={handleChange}
									method="POST"
									target="_blank"
									className="contactForm"
									id="franquiciasform"
								>
									<div>
										{/* <label htmlFor="name">
							<p>
								<Trans>Nombre y apellidos</Trans>:
							</p>
						</label> */}
										<input
											type="text"
											placeholder={t("Nombre y apellidos")}
											id="name"
											name="name"
											title=" "
											required
										/>
										<Separador />
									</div>

									<div>
										{/* <label htmlFor="phone">
							<p>
								<Trans>Teléfono de contacto</Trans>:
							</p>
						</label> */}
										<input
											type="tel"
											placeholder={t("Teléfono de contacto")}
											id="phone"
											name="phone"
											title=" "
											required
										/>
										<Separador />
									</div>

									<div>
										{/* <label htmlFor="from_email">
							<p>
								<Trans>Déjanos tu email</Trans>:
							</p>
						</label> */}
										<input
											type="email"
											placeholder={t("Email")}
											id="from_email"
											name="from_email"
											title=" "
											required
										/>
										<Separador />
									</div>

									<div>
										{/* <label htmlFor="city">
							<p>
								<Trans>¿En qué ciudad te gustaría abrir la franquicia?</Trans>:
							</p>
						</label> */}
										<TextareaAutosize
											placeholder={t(
												"¿En qué ciudad te gustaría abrir la franquicia?"
											)}
											id="city"
											name="city"
											title=" "
											required
											value={toSend.city}
											maxRows={4}
											minRows={1}
										/>

										{/* <input
											type="text"
											placeholder={t(
												"¿En qué ciudad te gustaría abrir la franquicia?"
											)}
											id="city"
											name="city"
											title=" "
											required
										/> */}
										<Separador />
									</div>

									<div className="radioInput">
										<p>
											<Trans>
												¿Alguna vez has tenido alguna vez algún negocio propio
												de restauración u hoteles?
											</Trans>
											:
										</p>

										<div>
											<div className="radioLabel">
												<input
													type="radio"
													id="si"
													name="experience"
													value="Sí"
													required
												/>
												<label htmlFor="si" className="radioLabel">
													<Trans>SÍ</Trans>
												</label>
											</div>
											<div className="separator" />
											<div className="radioLabel">
												<input
													type="radio"
													id="no"
													name="experience"
													value="No"
													required
												/>
												<label htmlFor="no" className="radioLabel">
													<Trans>NO</Trans>
												</label>
											</div>
										</div>
										<Separador />
									</div>

									<div className="radioInput">
										<p>
											<Trans>
												Indica con qué franja de capacidad de inversión te
												sentirás más cómodo
											</Trans>
											:
										</p>

										<div>
											<div className="radioLabel">
												<input
													type="radio"
													id="low"
													name="investment"
													value="750.000€ - 1.000.000€"
													required
												/>
												<label htmlFor="low" className="radioLabel">
													<Trans>De</Trans> 750.000€ - 1.000.000€
												</label>
											</div>
											<div className="separator" />
											<div className="radioLabel">
												<input
													type="radio"
													id="high"
													name="investment"
													value="Más de 1.000.000€"
													required
												/>
												<label htmlFor="high" className="radioLabel">
													<Trans>Más de</Trans> 1.000.000€
												</label>
											</div>
										</div>
										<Separador />
									</div>

									<div>
										<TextareaAutosize
											placeholder={t("¿Quieres comentarnos algo más sobre ti?")}
											id="comment"
											name="comment"
											title=" "
											required
											value={toSend.comment}
											maxRows={4}
											minRows={1}
										/>
										<Separador />
									</div>

									<div className="dataProtection">
										<div className="dataCheck">
											<input
												type="checkbox"
												name="data_protection"
												id="data_protection"
												required
											/>
											<label
												htmlFor="data_protection"
												aria-label={t(
													"Consiento el uso de mis datos personales para que atiendan mi solicitud"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento el uso de mis datos personales para que
														atiendan mi solicitud, según lo establecido en su{" "}
														<a
															href="/legal/politica-privacidad"
															target="_blank"
															rel="noopener noreferrer"
														>
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</a>{" "}
														y declaro tener cumplidos los catorce (14) años de
														edad, asumiendo las posibles responsabilidades
														legales sobre la veracidad de esta declaración.
													</Trans>
												</p>
											</label>
										</div>

										<div className="dataCheck">
											<input
												type="checkbox"
												name="com_comercial"
												id="com_comercial"
											/>
											<label
												htmlFor="com_comercial"
												aria-label={t(
													"Consiento la recepción de comunicaciones del restaurante por e-mail y/o SMS con fines comerciales"
												)}
											>
												<p>
													<Trans ns="dataProtection">
														Consiento la recepción de comunicaciones del
														restaurante por e-mail y/o SMS con fines comerciales
													</Trans>
												</p>
											</label>
										</div>
									</div>

									<div className="formSubmit">
										{loading && !formError ? (
											<p>Enviando...</p>
										) : (
											<>
												<button type="submit" aria-label={t("Enviar")}>
													<h3>
														<Subrayado1 className="subrayado" />
														<Trans>Enviar</Trans>
													</h3>
												</button>
												{formError && (
													<div>
														<p>
															<Trans>
																¡Ups! Ha habido un error en el envío del
																mensaje. ¡Lo sentimos!
															</Trans>
														</p>
														<p>
															<Trans>
																Vuelve a intentarlo en unos momentos. Si el
																problema persiste, también puedes contactarnos a
																través del chat o de nuestras Redes Sociales:
															</Trans>
														</p>
													</div>
												)}
											</>
										)}
									</div>

									<div className="dataProtection">
										<p>
											<Trans ns="dataProtection">
												Información básica sobre protección de datos
											</Trans>
											:
										</p>
										<table>
											<tbody>
												<tr>
													<td>
														<Trans ns="dataProtection">Responsable</Trans>:
													</td>
													<td>BEGIN RESTAURANTES, S.L.</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Domicilio</Trans>:
													</td>
													<td>
														Av. Cortes Valencianas, 50, CP 46015 Valencia
														(Valencia)
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Finalidad</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Atender su solicitud de información
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Legitimacion</Trans>
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos serán tratados solo con su
															consentimiento, al marcar la casilla mostrada en
															este formulario
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Destinatarios</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Sus datos no serán cedidos a terceros
														</Trans>
														.
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Derechos</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Tiene derecho a solicitarnos acceder a sus datos,
															corregirlos o eliminarlos, también puede
															solicitarnos limitar su tratamiento, oponerse a
															ello y a la portabilidad de sus datos,
															dirigiéndose a nuestra dirección postal o a
														</Trans>{" "}
														<a href="mailto:contacto@beginrestaurante.com">
															contacto@beginrestaurante.com
														</a>
													</td>
												</tr>
												<tr>
													<td>
														<Trans ns="dataProtection">Mas info</Trans>:
													</td>
													<td>
														<Trans ns="dataProtection">
															Dispone de más información en nuestra
														</Trans>{" "}
														<Link to="/legal/pollitica-privacidad">
															<Trans ns="dataProtection">
																Política de Privacidad
															</Trans>
														</Link>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</form>
							</div>
						) : (
							<div>
								<p>
									<Trans>
										Solicitud recibida. Pronto nos pondremos en contacto
										contigo.
									</Trans>
								</p>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
}
