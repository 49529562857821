import React from "react";

import "./franquiciaSlider.scss";

// Import components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function FranquiciaSlider({ images }) {
	return (
		<>
			<Swiper
				slidesPerView={"auto"}
				spaceBetween={30}
				centeredSlides={true}
				loop={true}
				id="franquiciaSlider"
				modules={[Autoplay]}
				autoplay={true}
			>
				{images.map((image, i) => (
					<SwiperSlide key={"slider" + i}>
						<GatsbyImage image={getImage(image)} alt="" />
					</SwiperSlide>
				))}
			</Swiper>
		</>
	);
}
